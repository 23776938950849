// Google Analytics
import ReactGA from 'react-ga4';

// scroll bar
import 'simplebar/src/simplebar.css';

// i18n
import 'src/locales/i18n';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// dogado-gravity
import '@dogado/gravity';
import '@dogado/gravity/dist/css/brands/wp-one.css';

// lightbox
import 'react-image-lightbox/style.css';

// chatbox
import 'react-chat-widget/lib/styles.css';

import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// redux
import { store, persistor } from './redux/store';
// theme
import ThemeProvider from './theme';
// components
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ToastProvider from './components/gravity/ToastProvider';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = 'G-32CFLD1G83';

ReactGA.initialize(GA_MEASUREMENT_ID);

// ----------------------------------------------------------------------

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <MotionLazyContainer>
          <ThemeProvider>
            <ToastProvider />
            <App />
            <ProgressBarStyle />
          </ThemeProvider>
        </MotionLazyContainer>
      </HelmetProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
