import { createApi } from '@reduxjs/toolkit/query/react';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

const REACT_APP_CHAT_BOT_AGENT_ID = '1ecdfae7-e907-4823-a17e-4f12ec289146';

// ----------------------------------------------------------------------
// Change history page

export const chatBotApi = createApi({
  reducerPath: 'chatBotApi',
  baseQuery: axiosBaseQuery({ baseUrl: 'https://api.synohq.com/v0' }),
  tagTypes: ['chat-bot'],
  endpoints: (builder) => ({
    // Sign in
    signIn: builder.mutation<{ accessToken: string; refreshToken: string }, {}>({
      query: () => ({
        url: '/authentication/sign-in',
        method: 'post',
        data: {
          email: 'joonas@zoner.fi',
          password: 'myrandompass',
        },
      }),
    }),
    // Get agent
    getAgent: builder.query<
      {
        id: string;
        name: string;
        prompt: string;
        model: string;
        channel: string;
        keyOrigin: string;
        language: string;
        temperature: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
      },
      {}
    >({
      query: () => ({
        url: `/agent/${REACT_APP_CHAT_BOT_AGENT_ID}`,
        method: 'get',
      }),
    }),
    // Start new session
    startSession: builder.mutation<
      {
        sessionId: string;
        response: string;
      },
      {
        message: string;
      }
    >({
      query: (data) => ({
        url: `/session/${REACT_APP_CHAT_BOT_AGENT_ID}`,
        method: 'post',
        data,
      }),
    }),
    // Emit message
    emitMessage: builder.mutation<
      {
        success: boolean;
        message: {
          response: string;
        };
      },
      {
        sessionId: string;
        content: string;
      }
    >({
      query: ({ sessionId, content }) => ({
        url: `/message/${sessionId}`,
        method: 'post',
        data: {
          content,
        },
      }),
    }),
    // End session
    endSession: builder.mutation<
      {
        id: string; // Session ID
        state: null;
        createdAt: string;
        endedAt: string;
      },
      {
        sessionId: string;
      }
    >({
      query: ({ sessionId }) => ({
        url: `/session/${sessionId}`,
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useLazyGetAgentQuery,
  useStartSessionMutation,
  useEmitMessageMutation,
  useEndSessionMutation,
} = chatBotApi;
