import type { BaseQueryFn } from '@reduxjs/toolkit/query';
// axios
import type { AxiosRequestConfig, AxiosError } from 'axios';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
/**
 *Due to the Same-Origin Policy and the withCredentials=true setting, it signals to the browser that it wants to include cookies associated with the domain in the request headers.
 *And the browser automatically includes the session cookie associated with the domain in the request headers.
 */

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      // console.log('Endpoint url:', method?.toUpperCase(), baseUrl + url);
      // console.log('Request body:', data);

      const result = await axios({
        url: baseUrl + url,
        withCredentials:
          window.location.hostname === 'localhost' &&
          window.location.port === '3000' &&
          !baseUrl.includes('api.synohq.com')
            ? false
            : true,
        method,
        data,
        params,
      });

      // console.log('Result:', result);

      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;

      const error = {
        status: err.response?.status,
        data: err.response?.data || err.message,
      };

      // console.log('Error:', error);

      return {
        error: error,
      };
    }
  };
