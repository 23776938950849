import { useEffect, useState } from 'react';
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  toggleMsgLoader,
  setQuickButtons,
  markAllAsRead,
  dropMessages,
} from 'react-chat-widget';
import styled from 'styled-components';
// redux
import { useSelector } from 'src/redux/store';
import {
  useEmitMessageMutation,
  useEndSessionMutation,
  useLazyGetAgentQuery,
  useSignInMutation,
  useStartSessionMutation,
} from 'src/redux/api/chatBotApi';
// @types
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const WidgetStyle = styled.div`
  .rcw-widget-container {
    width: 400px;
  }
  .rcw-widget-container > .rcw-launcher {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
  }
  .rcw-conversation-container > .rcw-header {
    padding: 8px 0 8px 0;
    background-color: var(--color-primary);
  }
  .rcw-header > .rcw-title {
    font-size: 16px;
  }
  .rcw-conversation-container > .rcw-sender {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .rcw-sender > .rcw-picker-btn {
    display: none;
  }
  .rcw-sender > .rcw-new-message {
    flex: 1;
  }
  .rcw-messages-container ul,
  .rcw-messages-container ol {
    list-style-position: inside;
  }
  .quick-buttons-container .quick-button {
    padding: 4px 16px 4px 16px;
    font-size: 14px;
    color: var(--color-surface-bright);
    background-color: var(--color-primary);
  }
`;

// ----------------------------------------------------------------------

export default function ChatBot() {
  // HOOK
  const { translate } = useLocales();

  // API
  const [signIn] = useSignInMutation();
  const [getAgent] = useLazyGetAgentQuery();
  const [startSession] = useStartSessionMutation();
  const [emitMessage] = useEmitMessageMutation();
  const [endSession] = useEndSessionMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [agentName, setAgentName] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  // EVENT FUNCTION
  async function handleStartingNewChat() {
    await signIn({})
      .unwrap()
      .then(() => {
        getAgent({})
          .unwrap()
          .then((res) => {
            addResponseMessage(translate('wponesupport.chatbot.messages.greetings'));
            markAllAsRead();

            setAgentName(res.name);
            setAuthenticated(true);
          })
          .catch(() => {
            throw new Error();
          });
      })
      .catch(() => {
        setAgentName(null);
        setAuthenticated(false);
      });
  }

  async function handleNewMessage(newMsg: string) {
    toggleMsgLoader();
    if (!sessionId) {
      await startSession({ message: newMsg })
        .unwrap()
        .then((res) => {
          setSessionId(res.sessionId);
          addResponseMessage(res.response);
        })
        .catch((err) => {
          console.log(err);
          addResponseMessage(translate('wponesupport.chatbot.messages.error'));
        });
    } else {
      await emitMessage({ sessionId, content: newMsg })
        .unwrap()
        .then((res) => {
          addResponseMessage(res.message.response);
        })
        .catch((err) => {
          console.log(err);
          addResponseMessage(translate('wponesupport.chatbot.messages.error'));
        });
    }
    toggleMsgLoader();
  }

  function handleEndSession() {
    if (!sessionId) return;
    endSession({ sessionId })
      .unwrap()
      .then(() => {
        setSessionId(null);
        toggleWidget();

        dropMessages();
        addResponseMessage(translate('wponesupport.chatbot.messages.greetings'));
        markAllAsRead();
      })
      .catch(() => {
        addResponseMessage(translate('wponesupport.chatbot.messages.error'));
      });
  }

  //
  useEffect(() => {
    handleStartingNewChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionId) {
      setQuickButtons([{ label: 'End chat', value: '0' }]);
    } else {
      setQuickButtons([]);
    }

    // To ensure that the session is ended when the user closes/refresh the tab from server side
    window.addEventListener('beforeunload', handleEndSession);

    return () => {
      window.removeEventListener('beforeunload', handleEndSession);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return authenticated && user?.role === UserRoleEnum.support ? (
    <div className="gv-activated">
      <WidgetStyle>
        <Widget
          handleNewUserMessage={handleNewMessage}
          handleQuickButtonClicked={handleEndSession}
          title={agentName}
          subtitle=""
        />
      </WidgetStyle>
    </div>
  ) : (
    <></>
  );
}
